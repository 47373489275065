// const politeHacker = "<p>Some potentially <strong onClick=\"alert('Hack!')\">dangerous</strong> HTML content</p>";
// <div v-sanitize="politeHacker" />

import DOMPurify from 'dompurify'

export default defineNuxtPlugin(async (nuxtApp) => {
  let JSDOM
  if (import.meta.server) {
    const { JSDOM: dom } = await import('jsdom')
    JSDOM = dom
  }

  nuxtApp.vueApp.directive('sanitize', {
    mounted: (el, binding) => {
      el.innerHTML = DOMPurify.sanitize(binding.value)
    },
    updated: (el, binding) => {
      el.innerHTML = DOMPurify.sanitize(binding.value)
    },
    getSSRProps(binding) {
      return {
        innerHTML: DOMPurify(new JSDOM('').window).sanitize(binding.value)
      }
    }
  })
})
